<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas="breadcrumb_data" />
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("spottrade_management.list_all") }}
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Dropdown-->
              <!-- <DatatableDropdown /> -->
              <!--end::Dropdown-->
              <!--begin::Button-->
              <router-link
                to="/spot-trade-transaction-pairs-management/new"
                class="btn btn-primary font-weight-bolder"
                v-if="checkPermission('crm.bank.create')"
              >
                <span class="svg-icon svg-icon-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <circle fill="#000000" cx="9" cy="15" r="6" />
                      <path
                        d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                  <!--end::Svg Icon--> </span
                >{{ $t("spottrade_management.add_new") }}
              </router-link>
              <!--end::Button-->
            </div>
          </div>
          <div class="card-body">
            <Datatable
                ref="datatable"
                :url="datatableUrl"
                :dataUrl="datatableDataUrl"
                @data="loadDatatableData($event)"
            >
                <!-- <template v-slot:head>
                    Table head özelleştirme
                </template> -->
                <template v-slot:body>
                  <tr v-for="(item, index) in datatableData" :key="'data-' + index">
                    <th scope="row">{{ item.id }}</th>
                    <td>{{ item.name }}</td>
                    <!-- <td>{{ item.currency_id }}</td>
                    <td>{{ item.base_currency_id }}</td> -->
                    <td>
                        <div class="d-flex flex-row">
                            <span class="badge mr-1" :class="statusClass(item.spot_status)">
                                {{ statusLabel(item.spot_status) }}
                            </span>
                            <span
                                class="badge cursor-pointer"
                                :class="[item.spot_status === 'active' ? 'badge-danger' : 'badge-success']"
                                @click="updateStatus(item, 'spot_status')"
                            >
                                <i class="fa text-white fa-xs" :class="[item.spot_status === 'active' ? 'fa-times' : 'fa-check']" />
                                {{ item.spot_status === 'active' ? $t("currencies_management.make_passive") : $t("currencies_management.activate") }}
                            </span>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex flex-row">
                            <span class="badge mr-1" :class="statusClass(item.margin_status)">
                                {{ statusLabel(item.margin_status) }}
                            </span>
                            <!-- @TODO it will be activate after margin_status update endpoint added from backend -->
                            <!-- <span
                                class="badge cursor-pointer"
                                :class="[item.margin_status === 'active' ? 'badge-danger' : 'badge-success']"
                                @click="updateStatus(item, 'margin_status')"
                            >
                                <i class="fa text-white fa-xs" :class="[item.margin_status === 'active' ? 'fa-times' : 'fa-check']" />
                                {{ item.margin_status === 'active' ? $t("currencies_management.make_passive") : $t("currencies_management.activate") }}
                            </span> -->
                        </div>
                    </td>
                    <td>{{ item.price }}</td>
                  </tr>
                </template>
            </Datatable>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Swal from 'sweetalert2';
import Breadcrumb from "@/components/layout/Breadcrumb.vue";
import Datatable from "@/components/datatable/Datatable.vue";
import api_old from "@/api/index_old";
// import DatatableDropdown from '@/components/datatable/Dropdown.vue'

export default {
  name: "BankList",
  data() {
    return {
      datatableUrl: "",
      datatableDataUrl: "",
      datatableData: [],
    }
  },
  components: {
    Breadcrumb,
    Datatable,
    // DatatableDropdown
  },
  computed: {
    ...mapState({
      permissions: (state) => state.customer.permissions,
      lang: (state) => state.global.lang,
    }),
    breadcrumb_data() {
      return {
        title: this.$t("general_management.title"),
        subtitle: [ this.$t("general_management.spot_trade_tp_title") ],
      };
    },
  },
  methods: {
    ...mapActions("spottradeManagement", ["UPDATE_STATUS"]),
    checkPermission(permission) {
      return this.permissions?.some(item => String(item.name) === String(permission));
    },
    loadDatatableData(data) {
      this.$set(this, "datatableData", data);
    },
    initDatatable() {
      this.$refs.datatable.loadData();
    },
    statusClass(status) {
      if(status === "active") {
          return 'badge-success';
      }
      return 'badge-danger';
    },
    statusLabel(status) {
      return (status) ? this.$t(`service.${status}`) : '';
    },
    /**
     * @param status_type: 'spot_status' | 'margin_status'
     */
    updateStatus(item, status_type = 'spot_status') {
      const isItemActive = item[status_type] === "active";
      const status = isItemActive ? "0" : "1";
      const bodyTextKey = isItemActive ? "make_passive" : "activate"
      Swal.fire({
        icon: isItemActive ? "warning" : "info",
        title: this.$t(`currencies_management.${bodyTextKey}`),
        showCancelButton: true,
        confirmButtonText: this.$t("commons.approve"),
        cancelButtonText: this.$t("commons.cancel"),
      }).then((result) => {
        if (result.value) {
            this.UPDATE_STATUS({ id: item.id, status, status_type }).then(() => {
                this.initDatatable();
            })
        }
      });
      
    },
  },
  created() {
    if (this.permissions?.length > 0 && !this.checkPermission("crm.bank.index")) {
      this.$router.push("/no-permission");
    }
    this.datatableUrl = api_old.spottrade_tp_datatable;
    this.datatableDataUrl = api_old.spottrade_tp_datatable_data;
  },
};
</script>
